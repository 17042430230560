import { Dialog } from '@headlessui/react'
import { plural } from "pluralize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faViruses, faBacteria, faWorm } from "@fortawesome/pro-duotone-svg-icons"
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { motion } from "framer-motion";

function Modal({ isOpen, setIsOpen, panel, selectedCard }) {

  if (!panel)
    return null

  const germens = panel.germens ?? []
  const germensByType = germens.reduce((acc, germs) => {
    const type = germs.type
    if (acc[type]) {
      acc[type].push(germs)
    } else {
      acc[type] = [germs]
    }
    return acc
  }, {})

  const antibioticResistanceGenes = panel.antibioticResistanceGenes ?? []
  const antibioticResistanceGenesByClass = antibioticResistanceGenes.reduce((acc, genes) => {
    const type = genes.class
    if (acc[type]) {
      acc[type].push(genes)
    } else {
      acc[type] = [genes]
    }
    return acc
  }, {})

  const germensCategories = germens.reduce((acc, germs) => {
    const category = germs.category
    if (category && category !== "NONE") {
      acc.add(category)
    }
    return acc
  }, new Set()) ?? []

  return (
    <Dialog
      as={motion.div}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      layoutId={selectedCard}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}

      className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
          <Dialog.Panel className="h-full rounded-xl bg-white w-full lg:w-11/12 2xl:w-1/2 p-4 sm:p-6 lg:p-10">
            <Dialog.Title className="text-xl font-medium text-gray-900">
              <div className="relative h-full w-full">
                <div onClick={() => setIsOpen(false)} className="absolute cursor-pointer top-0 right-0 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  <FontAwesomeIcon className="h-3 w-3 sm:h-5 sm:w-5" icon={faClose} />
                </div>
              </div>
              <div>
                <motion.h5>
                  {panel.text}
                  <small className="text-sm text-gray-500 ml-1 sm:ml-2" title="Target count">
                    ({germensCounter(germens)})
                  </small>
                </motion.h5>
              </div>
            </Dialog.Title>
            <Dialog.Description className="text-sm text-gray-800">
              Sample Type:{" "}
              <span className="italic text-gray-600">
                {panel.sampleType.join(", ")}
              </span>

            </Dialog.Description>
            {/* This hidden button is for avoid autofocus on the anchor */}
            <button type="hidden" autoFocus={true} />

            {germensByType &&
              <>
                <section className="mt-3">
                  <h2 id="policies-heading" className="sr-only">Germens</h2>
                  <dl className={`grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 md:gap-4`}>
                    {Object.entries(germensByType).map(([key, value]) => {
                      return (
                        <div className="bg-gray-50 border border-gray-200 rounded-lg p-2 sm:p-4 md:p-6">
                          <dt>
                            <div>
                              {iconByGermsType(key)}
                            </div>
                            <span className="mt-4 text-base font-medium text-gray-900 capitalize"> {key === "virus" ? key : plural(key)}
                              <span className="text-xs px-1 text-gray-500">
                                ({germensCounter(value)})
                              </span>
                            </span>
                          </dt>
                          {value.map((germen) =>
                            <dd className="mt-1 text-sm text-gray-500">{germen.display} {<Legend category={germen.category} />}</dd>
                          )}
                        </div>
                      )
                    }
                    )}
                  </dl>
                </section>
                <div className="space-x-1 sm:space-x-2 pt-1 sm:pt-2">
                  {[...germensCategories].map((category) => {
                    return <Legend category={category} expand />
                  })}
                </div>
              </>
            }
            {
              antibioticResistanceGenes &&
              antibioticResistanceGenes.length > 0 &&
              <div className="mt-6">
                <h2 className="font-medium text-gray-900">Antibiotic Resistance Genes</h2>
                <div className="mt-2 prose prose-sm text-gray-500">
                  <ul>
                    {Object.entries(antibioticResistanceGenesByClass).map(([key, value]) =>
                      <li key={key} className="text-sm">
                        <span className="capitalize">{key}:</span>
                        {value.map((gene) =>
                          <span className="text-sm italic text-gray-600"> {gene.genes.join(", ")}</span>
                        )}
                      </li>)}
                  </ul>
                </div>
              </div>
            }
            {panel.technicalSpecifications &&
              <div className="mt-6">
                <h2 className="font-medium text-gray-900">Technical Specifications</h2>
                <div className="mt-2 prose prose-sm text-gray-500 text-sm">
                  {panel.technicalSpecifications.map((spec) => <p>{spec}</p>)}
                </div>
              </div>
            }
            {panel.externalLink &&

              <div className="mt-3">
                <a href={panel.externalLink} target="_blank" rel="noreferrer" className="text-sm font-medium text-blue-600 hover:text-blue-700">
                  Learn more
                  <FontAwesomeIcon icon={faExternalLink} aria-hidden="true" className="h-3 w-3 ml-1" />
                </a>
              </div>
            }
          </Dialog.Panel>
        </div>
      </div>
    </Dialog >
  )
}

const germensCategoryMap = {
  "FUNGAL": {
    display: "Fungal",
    color: "violet",
    abbreviation: "F"
  },
  "PROTOZOAL": {
    display: "Protozoal",
    color: "orange",
    abbreviation: "P"
  },
  "SLOW_GROWING": {
    display: "Slow Growing",
    color: "blue",
    abbreviation: "SG"
  },
  "STREPTOCOCCUS_SPECIES": {
    display: "Streptococcus Species",
    color: "fuchsia",
    abbreviation: "SS"
  },
  "ANAEROBE": {
    display: "Anaerobe",
    color: "green",
    abbreviation: "A"
  },
  "NOT_TYPICALLY_TESTED_BY_CULTURE": {
    display: "Not Typically Tested by Culture",
    color: "red",
    abbreviation: "NTTBC"
  },
}

const germensCounter = (germens) => {
  return germens.reduce((acc, germs) => acc += germs.count ? germs.count : 1, 0)
}

const Legend = ({ category, expand = false }) => {
  if (category === "NONE") {
    return null
  }

  const color = germensCategoryMap[category]?.color ?? "gray"

  return (<div title={germensCategoryMap[category]?.display} className={`inline-flex items-baseline px-1.5 py-0 rounded-full text-xs font-medium bg-${color}-100 text-${color}-800`}>
    {germensCategoryMap[category]?.abbreviation ?? category}
    {expand && <span className="">: {germensCategoryMap[category]?.display}</span>}
  </div>)
}


const iconByGermsType = (type) => {
  switch (type) {
    case "virus":
      return <FontAwesomeIcon icon={faViruses} color="#15803d" size="2x" />
    case "bacteria":
      return <FontAwesomeIcon icon={faBacteria} color="#0e7490" size="2x" />
    case "parasitic":
      return <FontAwesomeIcon icon={faWorm} color="#b45309" size="2x" />
    default:
      return <FontAwesomeIcon icon={faViruses} color="#15803d" size="2x" />
  }
}

export default Modal