import { useState } from "react"

import "./App.css";
import { BodyChart } from "./BodyChart";
import Modal from "./Modal";
import { Panels } from "./Panels";

function App() {

  let [isOpen, setIsOpen] = useState(false)

  let [panel, setPanel] = useState(null)
  let [selectedCard, setSelectedCard] = useState(null)

  const setSelectedCardAndOpenModal = (card) => {
    window.gtag("event", "open_modal", {
      event_category: "open",
      event_label: card
    })
    setSelectedCard(card)
    setIsOpen(true)
    setPanel(Panels[card] ?? {})
  }


  return (
    <div className="h-full w-full">
      <div className="relative w-full h-full pt-16 pb-20 sm:pt-4 sm:pb-24">
        <div className="w-full h-full px-3">
          <BodyChart setSelectedCardAndOpenModal={setSelectedCardAndOpenModal} />
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} selectedCard={selectedCard} panel={panel} />
      {/* HACK OF THE MONTH */}
      <div>
        <span className="text-violet-800 bg-violet-100"></span>
        <span className="text-orange-800 bg-orange-100"></span>
        <span className="text-blue-800 bg-blue-100"></span>
        <span className="text-fuchsia-800 bg-fuchsia-100"></span>
        <span className="text-green-800 bg-green-100"></span>
        <span className="text-red-800 bg-red-100"></span>
      </div>
    </div>
  )
}

export default App;
