import { faArrowAltCircleRight } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Card({ text, id }) {
  const hasTwoLines = text && text.length > 31
  return (
    <div key={id} className={`inline-flex items-center justify-between w-60  px-2 py-2 ring-1 ring-inset ring-sky-500/20 rounded-md text-sky-600 bg-sky-100 group-hover:shadow group-hover:bg-sky-600 group-hover:text-white ${!hasTwoLines ? "mt-3" : ""}`} >
      <span className="tracking-tight text-sm font-semibold">
        {text}
      </span>
      <FontAwesomeIcon icon={faArrowAltCircleRight} />
    </div >
  )
}
